import CookieConsent from 'vanilla-cookieconsent'; // Doc: https://www.npmjs.com/package/vanilla-cookieconsent

window['gtag_enable_tcf_support'] = true;

// obtain plugin
let cc = initCookieConsent();

// run plugin with your configuration
cc.run({
	current_lang: 'en',
	autoclear_cookies: true,                   // default: false
	page_scripts: true,                        // default: false

	// mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
	// delay: 0,                               // default: 0
	// auto_language: null                     // default: null; could also be 'browser' or 'document'
	// autorun: true,                          // default: true
	// force_consent: false,                   // default: false
	// hide_from_bots: true,                   // default: true
	// remove_cookie_tables: false             // default: false
	// cookie_name: 'cc_cookie',               // default: 'cc_cookie'
	// cookie_expiration: 182,                 // default: 182 (days)
	// cookie_necessary_only_expiration: 182   // default: disabled
	// cookie_domain: location.hostname,       // default: current domain
	// cookie_path: '/',                       // default: root
	// cookie_same_site: 'Lax',                // default: 'Lax'
	// use_rfc_cookie: false,                  // default: false
	// revision: 0,                            // default: 0

	onFirstAction: function (user_preferences, cookie) {
		// callback triggered only once
		console.log('onFirstAction fired', user_preferences, cookie);
	},

	onAccept: function (cookie) {
		console.log('onAccept fired!', cookie);

		// If analytics category is disabled => load all iframes automatically
		console.log('iframemanager: loading all iframes');

		if (cc.allowedCategory('analytics')) {
			// document.querySelectorAll('script[data-category="analytics"]').forEach((o) => o.setAttribute('type', 'text/javascript'));
			document.querySelectorAll('script[data-category="analytics"]').forEach((o) => {
				let script = document.createElement('script');
				if (o.getAttribute('src')) {
					script.src = o.getAttribute('src');
				} else {
					script.text = o.innerHTML;
				}

				document.head.appendChild(script);
				o.remove();
			});

			gtag('consent', 'update', {
				'analytics_storage': 'granted'
			});
		}
		if (cc.allowedCategory('targeting')) {
			// document.querySelectorAll('script[data-category="targeting"]').forEach((o) => o.setAttribute('type', 'text/javascript'));
			document.querySelectorAll('script[data-category="targeting"]').forEach((o) => {
				let script = document.createElement('script');
				if (o.getAttribute('src')) {
					script.src = o.getAttribute('src');
					o.getAttributeNames().forEach((name) => {
						if (name !== 'src' && name !== 'type') {
							script.setAttribute(name, o.getAttribute(name));
						}
					});
				} else {
					o.getAttributeNames().forEach((name) => {
						if (name !== 'type') {
							script.setAttribute(name, o.getAttribute(name));
						}
					});
					script.text = o.innerHTML;
				}

				document.head.appendChild(script);
				o.remove();
			});

			gtag('consent', 'update', {
				'ad_user_data': 'granted',
				'ad_personalization': 'granted',
				'ad_storage': 'granted'
			});
		}
	},

	onChange: function (cookie, changed_preferences) {
		console.log('onChange fired!', cookie, changed_preferences);
	},

	languages: {
		'en': {
			consent_modal: {
				title: 'We use cookies!',
				description: 'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
				primary_btn: {
					text: 'Accept all',
					role: 'accept_all'              // 'accept_selected' or 'accept_all'
				},
				secondary_btn: {
					text: 'Reject all',
					role: 'accept_necessary'        // 'settings' or 'accept_necessary'
				}
			},
			settings_modal: {
				title: 'Cookie preferences',
				save_settings_btn: 'Save settings',
				accept_all_btn: 'Accept all',
				reject_all_btn: 'Reject all',
				close_btn_label: 'Close',
				cookie_table_headers: [
					{ col1: 'Type' },
					{ col2: 'Description' },
					// { col2: 'Domain' },
					// { col3: 'Expiration' },
					// { col4: 'Description' }
				],
				blocks: [
					{
						title: 'Cookie usage 📢',
						description: 'We use cookies to ensure the basic functionalities of the website, enhance your browsing experience and analyze site traffic. By accepting these cookies, you agree to the storing of cookies on your device. These cookies help us understand how you interact with our website, allowing us to improve and personalize your experience.<br>You have the option to customize your cookie preferences at any time.<br><br>For more information about the cookies we use and how we handle your data, please review our <a href="https://www.trustindex.io/privacy-policy/" target="_blank" class="cc-link">privacy policy</a>.'
					}, {
						title: 'Strictly Necessary Cookies',
						description: 'These cookies are required for the website to function properly, including tasks like presenting content, logging in, verifying your session, addressing your service requests, and performing various other functions.',
						toggle: {
							value: 'necessary',
							enabled: true,
							readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
						},
						cookie_table: [
							{
								col1: 'Functional Cookies',
								col2: 'For retaining user preferences, such as language choices, usernames, and other selections made during website usage.',
							},
							{
								col1: 'Session Cookies',
								col2: 'For connecting a user’s actions during a single browser session.',
							},
							{
								col1: 'Persistent Cookies',
								col2: 'For retaining user preferences and actions across a single website or multiple websites between browser sessions.',
							}
						]
					}, {
						title: 'Performance and Analytics Cookies',
						description: 'These cookies gather data regarding website usage, encompassing details like visited pages, traffic origins, user interests, content management, and various other measurements associated with the website’s performance and user engagement.',
						toggle: {
							value: 'analytics',     // your cookie category
							enabled: false,
							readonly: false
						},
						cookie_table: [             // list of all expected cookies
							{
								col1: 'Media Cookies',
								col2: 'For enhancing the website’s performance and offering unique features and content. They can be installed by us or third-party service providers working with us to enhance the website’s functionality.',
							}
						]
					}, {
						title: 'Advertising or Targeting Cookies',
						description: 'Advertisement cookies are used for delivering relevant ads and promotional campaigns to visitors. They monitor visitor activities across various websites, gathering data to tailor personalized advertisements.',
						toggle: {
							value: 'targeting',
							enabled: false,
							readonly: false
						}
					}, {
						title: 'More information',
						description: 'For any queries about our policy on cookies and your choices, please <a class="cc-link" href="https://www.trustindex.io/contact/">contact us</a>.',
					}
				]
			}
		}
	},

	gui_options: {
		consent_modal: {
			layout: 'bar',               // box/cloud/bar
			position: 'bottom center',     // bottom/middle/top + left/right/center
			transition: 'slide',           // zoom/slide
			swap_buttons: true            // enable to invert buttons
		},
		settings_modal: {
			layout: 'box',                 // box/bar
			position: 'right',              // left/right
			transition: 'slide'            // zoom/slide
		}
	}
});